import { TGenderPagesValues, TParentCategoryUrl } from '~/types/main';
import { TParentCategoryBackend } from '~/types/components/search';

export const PARENT_CATEGORY_URL_NAMES_MAP: Record<NonNullable<TGenderPagesValues>, TParentCategoryUrl> = {
  women: 'zhenskoe',
  men: 'muzhskoe',
  kids: 'detskoe',
  lifestyle: 'lifestyle',
};

export const MAPPING_PARENT_CATEGORY_FOR_BACKEND_NAMES: Record<NonNullable<TGenderPagesValues>, TParentCategoryBackend> = {
  women: 'woman',
  men: 'man',
  kids: 'kids',
  lifestyle: 'lifestyle',
};
