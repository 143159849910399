export function usePageDefinition() {
  const route = useRoute();

  const isCatalog = computed(() => route.fullPath.includes('catalog'));
  const isMain = computed(() => [
    'index',
    'women',
    'men',
    'kids',
    'lifestyle',
  ].includes(route.name as string));
  const isSecondMain = computed(() => route.fullPath.includes('homeBanner'));
  const isPublication = computed(() => route.fullPath.includes('publication'));
  const isAccounts = computed(() => route.fullPath.includes('account'));

  return {
    isCatalog,
    isMain,
    isSecondMain,
    isPublication,
    isAccounts,
  };
}
